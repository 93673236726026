<template>
  <v-text-field
    :id="id"
    ref="textInput"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="hideDetails"
    outlined
    :rules="rules"
    class="mt-2 pt-0"
    :prefix="prefixText"
    v-model.trim="quantityinput"
    :append-outer-icon="appendOuterIcon"
    :placeholder="placeholder"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:change="$emit('change', true)"
    v-on:keyup="$emit('keyup', true)"
    v-on:keypress="isNumber($event)"
    v-on:paste="onPaste"
  >
    <template v-slot:append>
      <slot name="appendItem"></slot>
    </template>

    <template v-slot:prepend>
      <slot name="prependItem"></slot>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    prefixText: {
      type: String,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      quantityinput: null,
    };
  },
  watch: {
    value(param) {
      this.quantityinput = param; //this.quantityinput;
    },
    quantityinput(param) {
      const quantity = this.formatQuantity(Number(param).toFixed(2));

      this.$emit("input", quantity);
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      let decimalPart = String(this.quantityinput).split(".");
      let nb = String(this.quantityinput);
      let decimalLength = decimalPart && decimalPart[1] ? decimalPart[1].length : 0;
      this.$emit("keypress", evt);

      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else if (this.quantityinput && String(this.quantityinput).includes(".") && charCode == 46) {
        evt.preventDefault();
      } else if (decimalLength >= 2 || nb.length > 8) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    onPaste(event) {
      event.preventDefault();
      // Optionally, you can notify the user that pasting is not allowed
      console.log("Pasting is not allowed in this input field");
    },
  },
  mounted() {
    this.quantityinput = this.formatQuantity(this.value);
  },
};
</script>
