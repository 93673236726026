<template>
  <div class="pos-number-keyboard">
    <v-layout class="pos-number-pad btx-border-radius-4px">
      <v-flex md12>
        <v-layout>
          <v-flex md4>
            <v-btn
              depressed
              tile
              v-on:click="pressedKey('1')"
              class="btx-border p-3 py-4 btx-border-top-left-radius-4px normal-text-3 w-100 h-100"
              >1</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('2')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >2</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              depressed
              tile
              v-on:click="pressedKey('3')"
              class="btx-border p-3 py-4 btx-border-top-right-radius-4px normal-text-3 w-100 h-100"
              >3</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('4')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >4</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('5')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >5</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('6')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >6</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('7')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >7</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('8')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >8</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('9')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >9</v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex md4>
            <v-btn
              depressed
              tile
              :disabled="haveDotInString()"
              v-on:click="pressedKey('.')"
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              ><v-icon color="black" x-small>mdi-record</v-icon></v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              v-on:click="pressedKey('0')"
              depressed
              tile
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              >0</v-btn
            >
          </v-flex>
          <v-flex md4>
            <v-btn
              depressed
              tile
              v-on:click="backspace()"
              class="btx-border p-3 py-4 normal-text-3 w-100 h-100"
              ><v-icon color="black" small>mdi-backspace-outline</v-icon></v-btn
            >
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex md12>
            <v-btn
              depressed
              tile
              v-on:click="$emit('close', true)"
              class="btx-border py-2 normal-text-3 btx-border-bottom-left-radius-4px btx-border-bottom-right-radius-4px w-100 h-100"
              >Hide Keyboard</v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import interact from "interactjs";
import { toString } from "lodash";
export default {
  name: "number-keyboard-template",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      key_number: null,
    };
  },
  watch: {
    value() {
      this.key_number = toString(this.value);
    },
    key_number() {
      this.$emit("input", toString(this.key_number));
    },
  },
  mounted() {
    this.key_number = this.value;
    this._initDragDrop();
  },
  methods: {
    haveDotInString() {
      const number = toString(this.key_number);
      const split_number = number.split(".");
      if (split_number.length > 1) {
        return true;
      }
      return false;
    },
    pressedKey(key) {
      const number = toString(this.key_number);
      this.key_number = number.concat(key);
    },
    backspace() {
      const key_number = toString(this.key_number);
      if (key_number.length) {
        this.key_number = key_number.substring(0, key_number.length - 1);
      } else {
        this.$emit("close", true);
      }
    },
    _initDragDrop() {
      const _this = this;
      // target elements with the "draggable" class
      interact(".pos-number-keyboard").draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "parent",
            endOnly: true,
          }),
        ],
        // enable autoScroll
        autoScroll: true,

        listeners: {
          // call this function on every dragmove event
          move: _this.dragMoveListener,

          // call this function on every dragend event
          end() {},
        },
      });
      window.dragMoveListener = _this.dragMoveListener;
    },
    dragMoveListener(event) {
      const target = event.target;
      // keep the dragged position in the data-x/data-y attributes
      const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
      const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // translate the element
      target.style.transform = "translate(" + x + "px, " + y + "px)";

      // update the position attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },
  },
};
</script>
