<template>
  <span :class="classData">
    <template v-if="value">
      <template v-if="date">{{ formatDate(value) }}</template>
      <template v-else-if="datetime">{{ formatDateTime(value) }}</template>
      <template v-else-if="currency">{{ formatCurrency(value) }}</template>
      <template v-else-if="image">
        <ImageTemplate
          :src="value"
          :maxWidth="70"
          :maxHeight="70"
          :aspectRatio="1"
          :contain="false"
          circle
        />
      </template>
      <template v-else>
        <slot name="value">
          <v-chip
            v-if="decoration.is"
            :class="'ma-0 px-2' + ' ' + decoration.customClass"
            :outlined="decoration.outlined"
            :color="decoration.bgColor"
            :text-color="decoration.textColor"
            small
          >
            {{ value }}
          </v-chip>
          <span v-else>{{ value }}</span>
        </slot>
      </template>
    </template>
    <template v-else>
      <em class="text--secondary">No {{ title }}</em>
    </template>
  </span>
</template>

<script>
import ImageTemplate from "@/view/components/Image";
export default {
  name: "value-template",
  data() {
    return {
      customClass: "",
    };
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    date: {
      type: Boolean,
      default: false,
    },
    datetime: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    classData: {
      type: String,
      default: null,
    },
    value: {
      type: [Number, String, Object, Boolean],
      default: null,
    },
    image: {
      type: Boolean,
      default: false,
    },
    decoration: {
      type: [Object, Boolean],
      default: () => {
        return { name: "", bgColor: "", textColor: "", customClass: "" };
      },
      mergeValue: {
        type: Array,
        default: null,
      },
    },
  },
  components: {
    ImageTemplate,
  },
};
</script>

<style scoped>
.bordered {
  border: 1px solid #666666;
}
</style>
