<template>
  <v-layout class="btx-search-criteria">
    <v-flex md10>
      <h6>Search Criteria</h6>
      <ul>
        <li>
          {{ searchString }} have a match for
          <span class="search-text">{{ search }}</span>
        </li>
      </ul>
    </v-flex>
    <v-flex md2 class="text-right">
      <v-btn
        depressed
        color="red lighten-1"
        class="text-white"
        tile
        text
        small
        v-on:click="$emit('close-search', true)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: {
    searchString: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: null,
    },
  },
};
</script>
