import Keyboard from "@/view/components/Keyboard";
import NumberKeyboard from "@/view/components/NumberKeyboard";
import { getData } from "@/core/services/local.service.js";
import { isEmpty } from "lodash";
import { formatMoney } from "accounting-js";

export default {
  data() {
    return {
      value_string_model: null,
      value_string_ref: null,
      value_number_model: null,
      value_number_ref: null,
      show_number_keyboard: false,
      show_keyboard: false,
      countries: [
        {
          text: "MY",
          value: "+60",
        },
        {
          text: "SG",
          value: "+65",
        },
      ],
    };
  },
  watch: {
    value_string_model(param) {
      const el = document.getElementById(this.value_string_ref);
      if (el) {
        el.value = param;
        el.dispatchEvent(new Event("input"));
        el.dispatchEvent(new Event("change"));
        el.dispatchEvent(new Event("keyup"));
      }

      this.focusField(this.value_string_ref);
    },
    value_number_model(param) {
      const el = document.getElementById(this.value_number_ref);
      if (el) {
        el.value = param;
        el.dispatchEvent(new Event("input"));
        el.dispatchEvent(new Event("change"));
        el.dispatchEvent(new Event("keyup"));
      }

      this.focusField(this.value_number_ref);
    },
  },
  methods: {
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    formatMoneys(money) {
      return formatMoney(money, {
        symbol: "",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    focusField(ref) {
      if (this.$refs[ref] && this.$refs[ref].$refs && this.$refs[ref].$refs.input) {
        this.$nextTick(() => {
          this.$refs[ref].$refs.input.focus();
        });
      }
    },
    getConfig(key) {
      const pos_config = getData("pos-config");
      if (!isEmpty(pos_config)) {
        return pos_config[key];
      }
      return new Object();
    },
    keyboardNumber(model, ref) {
      this.keyboardStringClose();
      this.$nextTick(() => {
        this.value_number_model = model;
        this.value_number_ref = ref;
        this.show_number_keyboard = true;
        this.focusField(this.value_number_ref);
      });
    },
    keyboardString(model, ref) {
      this.keyboardNumberClose();
      this.$nextTick(() => {
        this.value_string_model = model;
        this.value_string_ref = ref;
        this.show_keyboard = true;
        this.focusField(this.value_string_ref);
      });
    },
    keyboardStringClose() {
      this.value_string_model = null;
      this.value_string_ref = null;
      this.show_keyboard = false;
    },
    keyboardNumberClose() {
      this.value_number_model = null;
      this.value_number_ref = null;
      this.show_number_keyboard = false;
    },
    keyboardClose() {
      this.value_string_model = null;
      this.value_string_ref = null;
      this.value_number_model = null;
      this.value_number_ref = null;
      this.show_number_keyboard = false;
      this.show_keyboard = false;
    },
  },
  components: {
    Keyboard,
    NumberKeyboard,
  },
};
