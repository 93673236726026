<template>
  <div class="w-100 pos-keyboard py-3 px-5">
    <v-layout class="justify-center">
      <v-flex
        v-on:mousedown="keyMouseDown('one')"
        v-on:mouseup="keyMouseUp('one')"
        :class="{ 'pos-key-pressed': key_active.one }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          !
          <span>1</span>
        </template>
        <template v-else>
          1
          <span>!</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('two')"
        v-on:mouseup="keyMouseUp('two')"
        :class="{ 'pos-key-pressed': key_active.two }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          @
          <span>2</span>
        </template>
        <template v-else>
          2
          <span>@</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('three')"
        v-on:mouseup="keyMouseUp('three')"
        :class="{ 'pos-key-pressed': key_active.three }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          #
          <span>3</span>
        </template>
        <template v-else>
          3
          <span>#</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('four')"
        v-on:mouseup="keyMouseUp('four')"
        :class="{ 'pos-key-pressed': key_active.four }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          $
          <span>4</span>
        </template>
        <template v-else>
          4
          <span>$</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('five')"
        v-on:mouseup="keyMouseUp('five')"
        :class="{ 'pos-key-pressed': key_active.five }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          %
          <span>5</span>
        </template>
        <template v-else>
          5
          <span>%</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('six')"
        v-on:mouseup="keyMouseUp('six')"
        :class="{ 'pos-key-pressed': key_active.six }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          ^
          <span>6</span>
        </template>
        <template v-else>
          6
          <span>^</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('seven')"
        v-on:mouseup="keyMouseUp('seven')"
        :class="{ 'pos-key-pressed': key_active.seven }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          &
          <span>7</span>
        </template>
        <template v-else>
          7
          <span>&</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('eight')"
        v-on:mouseup="keyMouseUp('eight')"
        :class="{ 'pos-key-pressed': key_active.eight }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          *
          <span>8</span>
        </template>
        <template v-else>
          8
          <span>*</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('nine')"
        v-on:mouseup="keyMouseUp('nine')"
        :class="{ 'pos-key-pressed': key_active.nine }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          (
          <span>9</span>
        </template>
        <template v-else>
          9
          <span>(</span>
        </template>
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('zero')"
        v-on:mouseup="keyMouseUp('zero')"
        :class="{ 'pos-key-pressed': key_active.zero }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        <template v-if="shift_on">
          )
          <span>0</span>
        </template>
        <template v-else>
          0
          <span>)</span>
        </template>
      </v-flex>
      <template v-if="false">
        <v-flex
          v-on:mousedown="keyMouseDown('minus')"
          v-on:mouseup="keyMouseUp('minus')"
          :class="{ 'pos-key-pressed': key_active.minus }"
          class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
        >
          -
        </v-flex>
        <v-flex
          v-on:mousedown="keyMouseDown('plus')"
          v-on:mouseup="keyMouseUp('plus')"
          :class="{ 'pos-key-pressed': key_active.plus }"
          class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
        >
          +
        </v-flex>
      </template>
      <v-flex
        v-on:mousedown="keyMouseDown('backspace')"
        v-on:mouseup="keyMouseUp('backspace')"
        :class="{ 'pos-key-pressed': key_active.backspace }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-backspace text-center normal-text-2"
      >
        BACKSPACE
      </v-flex>
    </v-layout>
    <v-layout class="mt-3 justify-center">
      <v-flex
        v-on:mousedown="keyMouseDown('tab')"
        v-on:mouseup="keyMouseUp('tab')"
        :class="{ 'pos-key-pressed': key_active.tab }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        TAB
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('q')"
        v-on:mouseup="keyMouseUp('q')"
        :class="[{ 'pos-key-pressed': key_active.q }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        Q
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('w')"
        v-on:mouseup="keyMouseUp('w')"
        :class="[{ 'pos-key-pressed': key_active.w }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        W
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('e')"
        v-on:mouseup="keyMouseUp('e')"
        :class="[{ 'pos-key-pressed': key_active.e }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        E
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('r')"
        v-on:mouseup="keyMouseUp('r')"
        :class="[{ 'pos-key-pressed': key_active.r }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        R
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('t')"
        v-on:mouseup="keyMouseUp('t')"
        :class="[{ 'pos-key-pressed': key_active.t }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        T
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('y')"
        v-on:mouseup="keyMouseUp('y')"
        :class="[{ 'pos-key-pressed': key_active.y }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        Y
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('u')"
        v-on:mouseup="keyMouseUp('u')"
        :class="[{ 'pos-key-pressed': key_active.u }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        U
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('i')"
        v-on:mouseup="keyMouseUp('i')"
        :class="[{ 'pos-key-pressed': key_active.i }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        I
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('o')"
        v-on:mouseup="keyMouseUp('o')"
        :class="[{ 'pos-key-pressed': key_active.o }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        O
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('p')"
        v-on:mouseup="keyMouseUp('p')"
        :class="[{ 'pos-key-pressed': key_active.p }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        P
      </v-flex>
      <v-flex
        v-if="false"
        v-on:mousedown="keyMouseDown('backspace')"
        v-on:mouseup="keyMouseUp('backspace')"
        :class="{ 'pos-key-pressed': key_active.backspace }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-backspace text-center normal-text-2"
      >
        BACKSPACE
      </v-flex>
    </v-layout>
    <v-layout class="my-3 justify-center">
      <v-flex
        v-on:mousedown="keyMouseDown('caps')"
        v-on:mouseup="keyMouseUp('caps')"
        v-on:click="caps_on = !caps_on"
        :class="{ 'pos-key-pressed': key_active.caps, 'pos-caps-on': caps_on }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-caps text-center normal-text-2"
      >
        CAPS
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('a')"
        v-on:mouseup="keyMouseUp('a')"
        :class="[{ 'pos-key-pressed': key_active.a }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        A
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('s')"
        v-on:mouseup="keyMouseUp('s')"
        :class="[{ 'pos-key-pressed': key_active.s }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        S
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('d')"
        v-on:mouseup="keyMouseUp('d')"
        :class="[{ 'pos-key-pressed': key_active.d }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        D
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('f')"
        v-on:mouseup="keyMouseUp('f')"
        :class="[{ 'pos-key-pressed': key_active.f }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        F
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('g')"
        v-on:mouseup="keyMouseUp('g')"
        :class="[{ 'pos-key-pressed': key_active.g }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        G
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('h')"
        v-on:mouseup="keyMouseUp('h')"
        :class="[{ 'pos-key-pressed': key_active.h }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        H
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('j')"
        v-on:mouseup="keyMouseUp('j')"
        :class="[{ 'pos-key-pressed': key_active.j }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        J
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('k')"
        v-on:mouseup="keyMouseUp('k')"
        :class="[{ 'pos-key-pressed': key_active.k }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        K
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('l')"
        v-on:mouseup="keyMouseUp('l')"
        :class="[{ 'pos-key-pressed': key_active.l }, getAlphabetClasses()]"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        L
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('enter')"
        v-on:mouseup="keyMouseUp('enter')"
        :class="{ 'pos-key-pressed': key_active.enter }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-enter text-center normal-text-2"
      >
        ENTER
      </v-flex>
    </v-layout>
    <v-layout class="mb-3 justify-center">
      <v-flex
        v-on:mousedown="keyMouseDown('shift')"
        v-on:mouseup="keyMouseUp('shift')"
        :class="{ 'pos-key-pressed': key_active.shift, 'pos-shift-on': shift_on }"
        v-on:click="shift_on = !shift_on"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-shift text-center normal-text-2"
      >
        SHIFT
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('z')"
        v-on:mouseup="keyMouseUp('z')"
        :class="{ 'pos-key-pressed': key_active.z }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        Z
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('x')"
        v-on:mouseup="keyMouseUp('x')"
        :class="{ 'pos-key-pressed': key_active.x }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        X
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('c')"
        v-on:mouseup="keyMouseUp('c')"
        :class="{ 'pos-key-pressed': key_active.c }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        C
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('v')"
        v-on:mouseup="keyMouseUp('v')"
        :class="{ 'pos-key-pressed': key_active.v }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        V
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('b')"
        v-on:mouseup="keyMouseUp('b')"
        :class="{ 'pos-key-pressed': key_active.b }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        B
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('n')"
        v-on:mouseup="keyMouseUp('n')"
        :class="{ 'pos-key-pressed': key_active.n }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        N
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('m')"
        v-on:mouseup="keyMouseUp('m')"
        :class="{ 'pos-key-pressed': key_active.m }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet text-center normal-text-2"
      >
        M
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('shift')"
        v-on:mouseup="keyMouseUp('shift')"
        :class="{ 'pos-key-pressed': key_active.shift, 'pos-shift-on': shift_on }"
        v-on:click="shift_on = !shift_on"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-shift text-center normal-text-2"
      >
        SHIFT
      </v-flex>
    </v-layout>
    <v-layout class="justify-center">
      <v-flex
        v-on:mousedown="$emit('close', true)"
        class="p-2 mx-2 text-truncate cursor-pointer pos-key-alphabet pos-key-hide text-center normal-text-2"
      >
        HIDE KEYBOARD
      </v-flex>
      <v-flex
        v-on:mousedown="keyMouseDown('space')"
        v-on:mouseup="keyMouseUp('space')"
        :class="{ 'pos-key-pressed': key_active.space }"
        class="p-2 mx-2 cursor-pointer pos-key-alphabet pos-key-space text-center normal-text-2"
      >
        SPACE
      </v-flex>
      <v-flex
        v-on:mousedown="$emit('close', true)"
        class="p-2 mx-2 text-truncate cursor-pointer pos-key-alphabet pos-key-hide text-center normal-text-2"
      >
        HIDE KEYBOARD
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { toString, toUpper, toLower } from "lodash";

export default {
  name: "keyboard-template",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      caps_on: false,
      shift_on: false,
      key_string: null,
      key_active: {},
    };
  },
  watch: {
    value() {
      this.key_string = toString(this.value);
    },
    key_string() {
      this.$emit("input", toString(this.key_string));
    },
  },
  mounted() {
    this.key_string = this.value;
  },
  methods: {
    getAlphabetClasses() {
      let result = [];
      if (this.caps_on) {
        result.push("pos-caps-on");
      }
      if (this.shift_on) {
        result.push("pos-caps-on");
      }
      if (this.caps_on || this.shift_on) {
        result.push("text-uppercase");
      }
      if (!this.caps_on && !this.shift_on) {
        result.push("text-lowercase");
      }
      return result.join(" ");
    },
    doAction(key) {
      if (key == "one") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "!";
        } else {
          this.key_string = (this.key_string ?? "") + "1";
        }
      } else if (key == "two") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "@";
        } else {
          this.key_string = (this.key_string ?? "") + "2";
        }
      } else if (key == "three") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "#";
        } else {
          this.key_string = (this.key_string ?? "") + "3";
        }
      } else if (key == "four") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "$";
        } else {
          this.key_string = (this.key_string ?? "") + "4";
        }
      } else if (key == "five") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "%";
        } else {
          this.key_string = (this.key_string ?? "") + "5";
        }
      } else if (key == "six") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "^";
        } else {
          this.key_string = (this.key_string ?? "") + "6";
        }
      } else if (key == "seven") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "&";
        } else {
          this.key_string = (this.key_string ?? "") + "7";
        }
      } else if (key == "eight") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "*";
        } else {
          this.key_string = (this.key_string ?? "") + "8";
        }
      } else if (key == "nine") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + "(";
        } else {
          this.key_string = (this.key_string ?? "") + "9";
        }
      } else if (key == "zero") {
        if (this.shift_on) {
          this.key_string = (this.key_string ?? "") + ")";
        } else {
          this.key_string = (this.key_string ?? "") + "0";
        }
      } else if (key == "tab") {
        this.$emit("tab", true);
      } else if (key == "enter") {
        this.key_string = (this.key_string ?? "") + "\n";
      } else if (key == "space") {
        this.key_string = (this.key_string ?? "") + " ";
      } else if (key == "backspace") {
        const key_string = toString(this.key_string);
        if (key_string.length) {
          this.key_string = key_string.substring(0, key_string.length - 1);
        } else {
          this.$emit("close", true);
        }
      } else if (key && key.length == 1) {
        if (this.caps_on || this.shift_on) {
          this.key_string = (this.key_string ?? "") + toUpper(key);
        } else {
          this.key_string = (this.key_string ?? "") + toLower(key);
        }
      }
    },
    keyMouseDown(key) {
      this.key_active[key] = true;
    },
    keyMouseUp(key) {
      this.key_active[key] = false;
      this.doAction(key);
      /*if(this.shift_on){
        this.shift_on = false;
      }*/
      this.keyboardInit();
    },
    keyboardInit() {
      this.key_active = new Object({
        q: false,
        w: false,
        e: false,
        r: false,
        t: false,
        y: false,
        u: false,
        i: false,
        o: false,
        p: false,
        a: false,
        s: false,
        d: false,
        f: false,
        g: false,
        h: false,
        j: false,
        k: false,
        l: false,
        z: false,
        x: false,
        c: false,
        v: false,
        b: false,
        n: false,
        m: false,
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
        seven: false,
        eight: false,
        nine: false,
        zero: false,
        minus: false,
        plus: false,
        tab: false,
        caps: false,
        enter: false,
        shift: false,
        space: false,
        backspace: false,
      });
    },
  },
  beforeMount() {
    this.keyboardInit();
  },
};
</script>
