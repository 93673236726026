<template>
  <v-text-field
    :id="id"
    ref="textInput"
    :label="label"
    :loading="loading"
    :disabled="disabled"
    :readonly="readonly"
    :hide-details="hideDetails"
    outlined
    :prefix="currencySymbol"
    :rules="rules"
    class="mt-2 pt-0"
    v-model.trim="currencyinput"
    :append-outer-icon="appendOuterIcon"
    :placeholder="placeholder"
    v-on:click:append-outer="$emit('click:append-outer', true)"
    v-on:change="$emit('change', true)"
    v-on:keyup="$emit('keyup', true)"
    v-on:keypress="isNumber($event)"
  ></v-text-field>
</template>
<script>
export default {
  name: "text-input",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => {
        return [];
      },
    },
    placeholder: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currencyinput: null,
    };
  },
  watch: {
    value(param) {
      this.currencyinput = param;
    },
    currencyinput(param) {
      this.$emit("input", param);
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.currencyinput = this.value;
  },
};
</script>
