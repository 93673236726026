<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialog"
      persistent
      scrollable
      content-class="common-dialog"
      :max-width="dialogWidth"
      :fullscreen="fullscreen"
    >
      <v-card>
        <v-card-title
          v-if="$slots.title"
          :class="[
            'headline dialog-custom-header text-uppercase custom-header-blue-text white-background',
            { 'custom-border-bottom': !$slots.subtitle },
          ]"
        >
          <slot name="title"></slot>
        </v-card-title>

        <v-card-subtitle class="pt-3 pb-1 custom-border-bottom" v-if="$slots.subtitle">
          <slot name="subtitle"></slot>
        </v-card-subtitle>

        <v-card-text class="py-0" v-if="$slots.body" :class="getClasses()">
          <slot name="body"></slot>
        </v-card-text>
        <v-divider v-if="$slots.action" class="my-0"></v-divider>
        <v-card-actions v-if="$slots.action">
          <v-spacer></v-spacer>
          <slot name="action"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
export default {
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    cardTextClass: {
      type: String,
      default: null,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    dialogFullscreen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses() {
      const result = [];
      if (this.dense) {
        result.push("py-3 px-6");
      } else {
        result.push("p-8 ");
      }
      if (this.cardTextClass) {
        result.push(this.cardTextClass);
      }
      return result.join(" ");
    },
  },
};
</script>
