import ApiService from "@/core/services/api.service";

export const GET_CUSTOMERS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`supplier/list`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_LEAVE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`employee/leave`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_ADVANCED = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`employee/advanced`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CUSTOMER = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_UNLINKED_ORDERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/unlinked-orders`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_AVAILABLE_VOUCHERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/voucher/available`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_REDEEMED_VOUCHERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/voucher/redeemed`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_EXPIRED_VOUCHERS = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`customer/${id}/voucher/expired`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_CUSTOMER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("customer", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_CUSTOMER = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`customer/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TEMPLATE_LIST = () => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.GET("whatsapp/template/all")
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_PROMOTION_LIST = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`temp-promotions`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_PROMOTION_EXPIRY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`temp-promotions-expiry`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ---------supplier-------------
export const CREATE_SUPPLIER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("supplier", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPLOAD_FILE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/upload-image`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// export const UPDATE_STOCK = (params) => {
//   return new Promise((resolve, reject) => {
//     ApiService.setHeader();
//     ApiService.post(`/import-stock-count`, params)
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const FIND_STOCK = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`stock-count`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_STOCK = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-stock-count`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const STOCKUPDATE = (formData) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`/import-stock-count`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const CREATE_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-sale", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_QR_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-qr-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_DEBIT_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-debit-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_MASTER_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-master-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_QR_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-qr-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_DEBIT_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-debit-detail-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_MASTER_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-master-detail-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_QR_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-qr-month-Banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_DEBIT_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-debit-month-Banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_SALES = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`create-sale/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_QR_BANKING = (params, date, id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`create-qr-banking`, {
      params,
      date,
      id,
    })

      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_DEBIT_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-debit-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const UPDATE_PERCENTAGE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("update-percentage", {
      value: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CUST = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`customer`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_MASTER_SALE_UPDATE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("update-master-sale", {
      mastercard: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_MASTERCARD_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-mastecard-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_MASTERCARD_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-master-month-banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_VISA_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-visa-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_VISA_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-visa-detail-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_VISA_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-visa-month-banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_VISA_SALE_UPDATE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("update-visa-sale", {
      visacard: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_VISA_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-visa-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_GRAB_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-grab-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_GRAB_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-grab-detail-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_GRAB_SALE_UPDATE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("update-grab-sale", {
      grabfood: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_GRAB_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-grab-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_GRAB_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-grab-month-banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_ONLINE_BANKING_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-online-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_ONLINE_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-online-month-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_ONLINE_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-online-month-Banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_ONLINE_BANKING = (params, date, id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`create-online-banking`, {
      params,
      date,
      id,
    })

      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_RESERVATIONS = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`reservations`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_RESERVATION = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("customer-table-booking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const FIND_RESERVATION = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`get-reservation/${params}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_RESERVATION = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`customer-table-booking/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_All_RESERVATION = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    // ApiService.get(`purchase-order/supplier-filter`)
    ApiService.patch("reservation/filter", {
      id: params,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_QR_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`create-qr-sale`, {
      params,
    })

      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_ONLINE_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post(`create-online-sale`, {
      params,
    })

      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const CREATE_SALARY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("salary/add-edit", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


export const GET_LIST_SALARY = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-month-detail-salary`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_SALARY = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`salary/detail/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const UPDATE_SALARY = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`salary/add-edit/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_SALARY_MONTH = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-salary-month", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const GET_TOTAL_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-total-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TOUCH_SALES = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-touch-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TOUCH_LIST_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-touch-month-Banking`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_TOUCH_LIST_SALE = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`list-touch-detail-sale`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const CREATE_TOUCH_BANKING = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("create-touch-banking", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};